import React, {useState} from 'react';
import Header from "../includes/Header";

const AddSlider = () => {

    const [title, setTitle] = useState('')
    const [file, setFile] = useState('')
    const [description, setDescription] = useState('')

    async function addSlider() {
       console.log(title, file)
        const formData = new FormData()
        formData.append('file_path', file)
        formData.append('title', title)
        formData.append('description', description)

        let result = await fetch("https://api.jeebd.com/api/add-slider", {
            method: 'POST',
            body: formData
        })
        console.log(result)
        alert("Slider has been saved")
    }

    return (
        <div>
            <Header />
            <div className="col-sm-6 offset-sm-3">
                <h1>Add Slider</h1>
                <br/>
                <input type="text" placeholder="Enter About Title" onChange={(e) => setTitle(e.target.value)} className="form-control"/>
                <br/>
                <input type="file" placeholder="Enter About Image" onChange={(e) => setFile(e.target.files[0])} className="form-control"/>
                <br/>
                <input type="text" placeholder="Enter Blog Description" onChange={(e) => setDescription(e.target.value)} className="form-control"/>
                <br/>
                
                <button onClick={addSlider} className="btn btn-primary">Save</button>
            </div>
        </div>
    );
};

export default AddSlider;