import logo from './logo.svg';
import './App.css';
import './Header.css';
import Header from "./components/includes/Header";
import Sidebar from "./components/includes/Sidebar";
import React, { Component }  from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import Login from "./components/Login";
import Register from "./components/Register";
import AddProduct from "./components/products/AddProduct";
import UpdateProduct from "./components/products/UpdateProduct";
import Protected from "./components/Protected";
import ProductList from "./components/products/ProductList";
import SearchProduct from "./components/products/SearchProduct";
import AddBlog from "./components/blogs/AddBlog";

import AddClient from "./components/clients/AddClient";
import ClientList from "./components/clients/ClientList";

import AddGallery from "./components/gallery/AddGallery";
import GalleryList from "./components/gallery/GalleryList";
import ImportList from "./components/imports/ImportList";
import ExportList from "./components/exports/ExportList";
import AddCompany from "./components/company/AddCompany";
import CompanyList from "./components/company/CompanyList";
import AddMember from "./components/member/AddMember";
import MemberList from "./components/member/MemberList";
import AddBrand from "./components/brand/AddBand";
import BrandList from "./components/brand/BrandList";
import AddExport from "./components/exports/AddExport";
import AddImport from "./components/imports/AddImport";
import AddAbout from "./components/about/AddAbout";
import AboutList from "./components/about/AboutList";
import AddSlider from "./components/sliders/AddSlider";
import SliderList from "./components/sliders/SliderList";
import UpdateBlog from "./components/blogs/UpdateBlog";
import BlogList from "./components/blogs/BlogList";
import SearchBlog from "./components/blogs/SearchBlog";
import Profile from "./components/user/Profile";
import ShowBlog from './components/blogs/ShowBlog';
import Home from './components/blogs/Home';
import About from './components/About';
import Contact from './components/Contact';
import Gallery from './components/Gallery';
import Export from './components/Export';
import Clients from './components/Clients';
import Import from './components/Import';
import Blogs from './components/blogs/Articles';
import Articles from './components/blogs/Articles';
import ContactList from "./components/contacts/ContactList";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
console.log(logo); 
function App() {
  return (
    <div className="App">
        
        <BrowserRouter>
       
            <Switch>
                <Route path="/login">
                    <Login/>
                </Route>
                <Route exact path="/">
                    <Home/>
                </Route>
                <Route path="/register">
                    <Register/>
                </Route>
                <Route path="/blog-details/:id">
                    <ShowBlog/>
                </Route>
                <Route path="/blogs">
                    <Articles/>
                </Route>
                <Route path="/search">
                    <SearchBlog />
                </Route>
                <Route path="/import">
                <Import />
                </Route>

                <Route path="/export">
                <Export />
                </Route>

                <Route path="/clients">
                <Clients />
                </Route>
                <Route path="/gallery">
                <Gallery />
                </Route>

                <Route path="/contact">
                <Contact />
                </Route>
                <Route path="/About">
                <About />
                </Route>
                
                
                <Route path="/profile/:id">
                    <Protected Cmp={Profile} />
                </Route>
                <Route path="/addblog">
                    <Protected Cmp={AddBlog} />
                </Route>
                <Route path="/add-client">
                    <Protected Cmp={AddClient} />
                </Route>
                <Route path="/add-brand">
                    <Protected Cmp={AddBrand} />
                </Route>
                <Route path="/brand-list">
                    <Protected Cmp={BrandList} />
                </Route>
                <Route path="/add-gallery">
                    <Protected Cmp={AddGallery} />
                </Route>
                <Route path="/add-company">
                    <Protected Cmp={AddCompany} />
                </Route>
                
                <Route path="/add-export">
                    <Protected Cmp={AddExport} />
                </Route>
                <Route path="/add-import">
                    <Protected Cmp={AddImport} />
                </Route>
                <Route path="/export-list">
                    <Protected Cmp={ExportList} />
                </Route>
                <Route path="/import-list">
                    <Protected Cmp={ImportList} />
                </Route>
                <Route path="/add-about">
                    <Protected Cmp={AddAbout} />
                </Route>
                <Route path="/about-list">
                    <Protected Cmp={AboutList} />
                </Route>
                <Route path="/add-member">
                    <Protected Cmp={AddMember} />
                </Route>
                <Route path="/add-slider">
                    <Protected Cmp={AddSlider} />
                </Route>

                <Route path="/update/:id">
                    <Protected Cmp={UpdateBlog} />
                </Route>
                <Route path="/search">
                    <Protected Cmp={SearchBlog} />
                </Route>
                <Route path="/contact-list">
                    <Protected Cmp={ContactList} />
                </Route>

                <Route path="/member-list">
                    <Protected Cmp={MemberList} />
                </Route>
                <Route path="/company-list">
                    <Protected Cmp={CompanyList} />
                </Route>
                <Route path="/slider-list">
                    <Protected Cmp={SliderList} />
                </Route>

                <Route path="/client-list">
                    <Protected Cmp={ClientList} />
                </Route>
                <Route path="/gallery-list">
                    <Protected Cmp={GalleryList} />
                </Route>
                {/* <Route path="/productlist">
                    <Protected Cmp={ProductList} />
                </Route> */}
            </Switch>
        </BrowserRouter>
    </div>
  );
}

export default App;
