import React, {useState, useEffect} from 'react';
import exportimg from "../Media/export-top.jpeg";
import Footer from "./Footer";
import Navbar from "./Navbar";
function Export() {
  const [data, setData] = useState([])
    useEffect( () => {
        getData()
    },[])

    async function getData() {
        let result = await fetch('https://api.jeebd.com/api/export-list')
        result = await result.json()
        setData(result)
    }
  return (
    <>
    <Navbar />
      {/* Hero Section */}
      <div className="container-fluid heading">
        <div className="headingbox">
          <h1>Exports</h1>
        </div>
      </div>
      {/* Hero Section */}
      {/* export details */}
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-12 col-lg-12 col-sm-6 mx-auto">
            <div className="row about gy-2">
              <div className="col-lg-6 about_desc">
                <h1>Our Exports</h1>
                <p>Begining with pilot air knife system for POSCO's R&D in july 2003,we have been Supplying our air knife to system to leading Companies in Bangladesh and Global. 
<br></br>We provide pot roll equipement, EMSS & CWG which is front & rear of air knife equipment in CGL with our partners.Sink roll Receives the strip from NOF in Zinc pot and passes it to cooling tower vertically.Stablizer Roll is Located on the outgoing side of sink roll in zinc pot and passes the strip stably.<br></br>
We are equipped with casting facility (sand casting, centrifugal casting, investment casting) and expporting fod CGL's parts and other various products. Besides ,we are manufacturing the power plant's parts and other various products .Besides ,we are manufacturing the power Plant's Parts, industrial valve and various products which are demanded an abrasion-resistance, heat resistance, corrosion-resistance using the pTA welding .<br></br>
Beside this we export so many goods to many various country.
                </p>
                {/* <button>View More</button> */}
              </div>
              <div className="col-lg-6 ">
                <img className="about_img" src={exportimg} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="client container mt-5 mb-5">
        <div className="col-12 col-lg-12 col-sm-12 mx-auto">
          <div className="row">
            {
            data.map((item) =>
            <div className="col-lg-3 col-sm-6 col-6 col-md-4" key={item.id}>
              <div className="clients">
                <img className="client" src={'https://api.jeebd.com/export/'+item.file_path} alt="" />
              </div>
            </div>
            )
            }
          </div>
        </div>
      </div>
      {/* export details */}
      <Footer />
    </>
  );
}

export default Export;
