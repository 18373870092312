import React, {useState} from 'react';
import Footer from "./Footer";
import "../App.css";
import Navbar from "./Navbar";

const Contact = () => {
    
    const [fname, setFname] = useState('')
    const [lname, setLname] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')

    async function contact() {
      
        const formData = new FormData()
        formData.append('fname', fname)
        formData.append('lname', lname)
        formData.append('email', email)
        formData.append('message', message)
        let result = await fetch("https://api.jeebd.com/api/add-contact", {
            method: 'POST',
            body: formData
        })
        console.log(result)
        alert("Contact Form submitted !")
        
    }

    return (
        <>
    <Navbar />
      {/* Hero Section */}
      <div className="container-fluid heading">
        <div className="headingbox">
          <h1>Contact Us</h1>
        </div>
      </div>
      {/* Hero Section */}

      <div className="container-fluid contact">
        <div className="contact_box1">
          <h1>Request  quote</h1>
            <label for="fname">First name:</label>
            <br />
            <input type="text" id="fname" name="fname" placeholder="First name" onChange={(e) => setFname(e.target.value)} className="form-control"/>
                <br/>
                
            <br />
            <label for="lname">Last name:</label>
            <br />
            <input type="text" placeholder="Last name" onChange={(e) => setLname(e.target.value)} className="form-control"/>
            <br />
            <label for="Email">Email Address:</label>
            <br />
            <input type="email" placeholder="Enter Email" onChange={(e) => setEmail(e.target.value)} className="form-control"/>
            <br />
            <br />
            <p>
              <label for="message">Send Message:</label>
            </p>
            <textarea
              id="message"
              name="message"
              rows="4"
              cols="50"onChange={(e) => setMessage(e.target.value)} className="form-control"></textarea>
            <br />
            <br />
            <button onClick={contact} className="btn btn-primary">Submit</button>
        </div>
        <div className="contact_box">
          <h1>Call us Now!</h1>
           <div className="call">
            <h4>Phone : <a href="tel:031718466"> 031 718466, 031 716489 </a></h4>
            <h4>Fax : <a href="tel:0312524994"> +088 031 2524994</a></h4>
            <h4>Email : <a href="mailto:info@jeebd.com"> info@jeebd.com</a></h4>
            <h4>Location : <span>1082, D.T Road, Dhanialapara, Chattagram.</span></h4>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3690.450326207455!2d91.8144986744261!3d22.336618941579662!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30acd8b9f5d52a79%3A0x6cbd459525158015!2z4KaH4KaJ4Kao4Ka-4KaH4Kaf4KeH4KahIOCmleCmruCmvuCmsOCnjeCmtuCmv-Cmr-CmvOCmvuCmsiDgpqzgp43gpq_gpr7gpoLgppUg4Kay4Ka_4Kau4Ka_4Kaf4KeH4Kah!5e0!3m2!1sbn!2sbd!4v1704703427353!5m2!1sbn!2sbd" width="400" height="330" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
           </div>
        </div>
      </div>
      
      <Footer />
    </>
        
    );
};

export default Contact;
