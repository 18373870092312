import React, {useState, useEffect} from 'react';
import Header from "../includes/Header";
import {Table} from "react-bootstrap";
import {Link} from "react-router-dom";

const ClientList = () => {

    const [data, setData] = useState([])
    useEffect( () => {
        getData()
    },[])
    async function getData() {
        let result = await fetch('https://api.jeebd.com/api/client-list')
        result = await result.json()
        setData(result)
    }

    async function deleteClient(id) {

        
        let result = await fetch('https://api.jeebd.com/api/client-delete/'+id+'?_method=DELETE', {
            method: 'DELETE',
        })
        alert("Client has been deleted")
        getData()
    }

    return (
        <div>
            <Header />
            <div className="col-sm-8 offset-sm-2">
                <h1>Client List</h1>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Title</th>
                        <th>Image</th>
                        {/* <th>Description</th> */}
                        <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        data.map((item) =>
                            <tr key={item.id}>
                                <td>{item.id}</td>
                                <td>{item.title}</td>
                                <td><img width='80' height='80' src={'https://api.jeebd.com/client/'+item.file_path} /></td>
                                {/* <td>{item.description}</td> */}
                                <td>
                                    {/* <Link to={'update/'+item.id} className='btn btn-info btn-sm ml-2'>Update</Link> */}
                                    {/* <Link to={'blog-details/'+item.id} className='btn btn-info btn-sm ml-2'>Show</Link> */}
                                    <button className="btn btn-primary" onClick={() => deleteClient(item.id)}>Delete</button>
                                </td>
                            </tr>
                        )
                    }
                    </tbody>
                </Table>  
                </div>
                </div>

    );
};

export default ClientList;
