import React, {useState} from 'react';
import Header from "../includes/Header";

const AddGallery = () => {

    const [title, setTitle] = useState('')
    const [file, setFile] = useState('')

    async function addGallery() {
       console.log(title, file)
        const formData = new FormData()
        formData.append('file_path', file)
        formData.append('title', title)

        let result = await fetch("https://api.jeebd.com/api/add-gallery", {
            method: 'POST',
            body: formData
        })
        alert("Gallery has been saved")
    }

    return (
        <div>
            <Header />
            <div className="col-sm-6 offset-sm-3">
                <h1>Add Gallery</h1>
                <br/>
                <input type="text" placeholder="Enter Gallery Title" onChange={(e) => setTitle(e.target.value)} className="form-control"/>
                <br/>
                <input type="file" placeholder="Enter Gallery Image" onChange={(e) => setFile(e.target.files[0])} className="form-control"/>
                <br/>
                <button onClick={addGallery} className="btn btn-primary">Save</button>
            </div>
        </div>
    );
};

export default AddGallery;