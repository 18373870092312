import React, {useState, useEffect} from 'react';
import Header from "../includes/Header";
import {Table} from "react-bootstrap";
import {Link} from "react-router-dom";

const ContactList = () => {

    const [data, setData] = useState([])
    useEffect( () => {
        getData()
    },[])
    async function getData() {
        let result = await fetch('https://api.jeebd.com/api/contact-list')
        result = await result.json()
        setData(result)
    }
    async function deleteBlog(id) {

        
        let result = await fetch('https://api.jeebd.com/api/contact-delete/'+id+'?_method=DELETE', {
            method: 'DELETE',
        })
        alert("Contact has been deleted")
        getData()
    }

    return (
        <div>
            <Header />
            <div className="col-sm-8 offset-sm-2">
                <h1>Contact List</h1>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Message</th>
                        <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        data.map((item, index) =>
                            <tr key={item.id}>
                                <td>{item.id}</td>
                                <td>{item.fname} {item.lname} </td>
                                <td>{item.email}</td>
                                <td>{item.message}</td>
                                <td>
                                    <button className="btn btn-primary" onClick={() => deleteBlog(item.id)}>Delete</button>
                                </td>
                            </tr>
                        )
                    }
                    </tbody>
                </Table>  
                </div>
                </div>

    );
};

export default ContactList;
