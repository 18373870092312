import React from "react";

function Footer() {
  return (
    <>
      <div className="footer container-fluid">
        <div className="footer_text">
          <p>Copyright 2023 JE Enterprise</p>
        </div>
        <div className="footer_text">
          <p>Developed by Coderspoint</p>
        </div>
      </div>
    </>
  );
}

export default Footer;
