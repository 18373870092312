import React, {useState, useEffect} from 'react';

import Footer from "../Footer";
import Navbar from "../Navbar";
import {Table} from "react-bootstrap";
import {Link} from "react-router-dom";

import img1 from "../Media/Hero1.png";
import img2 from "../Media/Hero2.png";
import img3 from "../Media/Hero3.png";
import rocket from "../Media/rocket.png";
import b1 from "../Media/b1.png";
import { CDBCarousel, CDBCarouselItem, CDBCarouselInner, CDBView, CDBContainer } from 'cdbreact';
const Articles = () => {

    const [slider, setSlider] = useState([])
    useEffect( () => {
        getSlider()
    },[])
    async function getSlider() {
      let slider = await fetch('https://api.jeebd.com/api/slider-list')
      slider = await slider.json()
      setSlider(slider)
  }
  const [about, setAbout] = useState([])
    useEffect( () => {
      getAbout()
    },[])
    async function getAbout() {
      let about = await fetch('https://api.jeebd.com/api/about-list')
      about = await about.json()
      setAbout(about)
  }
  const [brand, setBrand] = useState([])
    useEffect( () => {
      getBrand()
    },[])
    async function getBrand() {
      let brand = await fetch('https://api.jeebd.com/api/brand-list')
      brand = await brand.json()
      setBrand(brand)
  }

  const [member, setMember] = useState([])
  useEffect( () => {
    getMember()
  },[])
  async function getMember() {
    let member = await fetch('https://api.jeebd.com/api/member-list')
    member = await member.json()
    setMember(member)
}
    return (
        <>
 <Navbar />
          {/* Hero Section */}
          <div className="carousel slide" >
            <CDBCarousel
          activeItem={0}
          length={3}
          showControls={true}
          showIndicators={true}
          className="z-depth-1"
          slide
        >
          <CDBCarouselInner>
            {
                        slider.map((item, index) =>
            <CDBCarouselItem itemId={index} key={item.id}>
              <CDBView>
                <img className="d-block w-100" src={'https://api.jeebd.com/sliders/'+item.file_path} alt="Second slide" />
              </CDBView>
            </CDBCarouselItem>
                )
              }
          </CDBCarouselInner>
        </CDBCarousel>
          </div>
          {/* Hero Section */}

          {/* Mission */}
                    {
                        about.map((item) =>
          <div className="container mt-5 mb-5" key={item.id}>
            <div className="row">
              <div className="col-12 col-lg-12 col-sm-6 mx-auto">
                <div className="row about gy-2">
                  <div className="col-lg-6 about_desc">
                    <h1>{item.title}</h1>
                    <p>
                    {item.description}
                    </p>
                    <a href="https://jeebd.com/about" >View More</a>
                  </div>
                  <div className="col-lg-6 ">
                    <img className="about_img" src={'https://api.jeebd.com/about/'+item.file_path} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          )
        }
          {/* Mission */}
  
    
          {/* Total visitor */}
    
          <div className="container-fluid visitor">
            <div className="visitor_box">
              <h1>Total Visitor</h1>
              <p>
                
              </p>
              <hr></hr>
              <h3>1000+</h3>
            </div>
    
            <div className="visitor_box2">
              <img className="Vsion" src={rocket} alt="" />
            </div>
          </div>
          {/* Total visitor */}
    
          {/* brands */}
          <div className="brands">
            <div className="container">
              <div className="row align-items-center">
                <div className="container rounded">
                  <h1 className="text-center">Our Brands</h1>
                  <div className="slider">
                    <div className="logos">
                    {
                    brand.map((item) =>
                      <img className="fab Vsion" key={item.id} src={'https://api.jeebd.com/brands/'+item.file_path} alt="" />
                      )
                    }
                     
                    </div>
                    <div className="logos">
                    {
                    brand.map((item) =>
                      <img className="fab Vsion" key={item.id} src={'https://api.jeebd.com/brands/'+item.file_path} alt="" />
                      )
                    }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    
          {/* We are member of  */}
          <div className="brands">
            <div className="container">
              <div className="row align-items-center">
                <div className="container rounded">
                  <h1 className="text-center">We are member of</h1>
                  <div className="client container">
            <div className="col-12 col-lg-12 col-sm-12 mx-auto">
              <div className="row">
        
            {
            member.map((item) =>
                <div className="col-lg-3 col-sm-6 col-6 col-md-4" key={item.id}>
                  <div className="clients">
                    <img className="client" src={'https://api.jeebd.com/members/'+item.file_path} alt="" />
                  </div>
                </div>
                )
              }
            
              </div>
            </div>
          </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </>
      );
};

export default Articles;

