import React, {useState, useEffect} from 'react';
import Footer from "./Footer";
import Navbar from "./Navbar";
function Gallery() {
  const [data, setData] = useState([])
    useEffect( () => {
        getData()
    },[])
    async function getData() {
        let result = await fetch('https://api.jeebd.com/api/gallery-list')
        result = await result.json()
        setData(result)
        console.log(result)
    }
  return (
    <>
    <Navbar />
      {/* Hero Section */}
      <div className="container-fluid heading">
        <div className="headingbox">
          <h1>Gallery</h1>
        </div>
      </div>
      {/* Hero Section */}
      <div className="container mt-2 mb-2">
        <div className="col-12 col-lg-12">
          <div class="row">
          {
          data.map((item, index) =>
            <div class="col-lg-4 col-md-12 mb-4 mb-lg-0">
           <img
           src={'https://api.jeebd.com/gallery/'+item.file_path}
           class="w-100 shadow-1-strong rounded mb-4"
           alt="Boat on Calm Water"
         />
            </div>
  )
}

{/* {
data.map((item, index) => (
  item.id == "2" ? (
    <img 
   src={'https://api.jeebd.com/gallery/'+item.file_path}
   class="w-100 shadow-1-strong rounded mb-4"
   alt="Wintry Mountain Landscape"
 />
    ) : ("")
  )
)
} */}

            
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Gallery;
