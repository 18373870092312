import React, {useState, useEffect} from 'react';
import b1 from "../Media/b1.png";
import Footer from "./Footer";
import Navbar from "./Navbar";
function Clients() {
  const [data, setData] = useState([])
    useEffect( () => {
        getData()
    },[])

    async function getData() {
        let result = await fetch('https://api.jeebd.com/api/client-list')
        result = await result.json()
        setData(result)
    }
  return (
    <>
    <Navbar />
      {/* Hero Section */}
      <div className="container-fluid heading">
        <div className="headingbox">
          <h1>Our Clients</h1>
          <p>Here are some of our clients.</p>
        </div>
      </div>
      {/* Hero Section */}
      <div className="client container mt-5 mb-5">
        <div className="col-12 col-lg-12 col-sm-12 mx-auto">
          <div className="row">
            {
            data.map((item) =>
            <div className="col-lg-3 col-sm-6 col-6 col-md-4" key={item.id}>
              <div className="clients">
                <img className="client" src={'https://api.jeebd.com/client/'+item.file_path} alt="" />
              </div>
            </div>
            )
            }
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Clients;
