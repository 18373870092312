import React from 'react';
import {Navbar, Nav, NavDropdown} from 'react-bootstrap'
import {Link, useHistory} from "react-router-dom";

const Header = () => {
    const history = useHistory()
    const user = JSON.parse(localStorage.getItem('user-info'));
    function logout() {
        localStorage.clear()
        history.push('/register')
    }

    return (
        <div>
            <Navbar bg="dark" variant="dark">
                <Navbar.Brand>JE Enterprise</Navbar.Brand>
                <Nav className="mr-auto nav-bar-wrapper">
                    {
                        localStorage.getItem('user-info') ?
                            <>
                            <NavDropdown title="About" className="pr-5">
                                <NavDropdown.Item><Link to={'/add-about/'}>Add About</Link></NavDropdown.Item>
                                <NavDropdown.Item><Link to={'/about-list/'}>About List</Link></NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="Slider" className="pr-5">
                                <NavDropdown.Item><Link to="/add-slider/">Add Slider</Link></NavDropdown.Item>
                                <NavDropdown.Item><Link to="/slider-list/">Slider List</Link></NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="Client" className="pr-5">
                                <NavDropdown.Item><Link to="/add-client/">Add Client</Link></NavDropdown.Item>
                                <NavDropdown.Item><Link to="/client-list/">Client List</Link></NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="Member" className="pr-5">
                                <NavDropdown.Item><Link to="/add-member/">Add Member</Link></NavDropdown.Item>
                                <NavDropdown.Item><Link to="/member-list/">Member List</Link></NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="Gallery" className="pr-5">
                                <NavDropdown.Item><Link to="/add-gallery/">Add Gallery</Link></NavDropdown.Item>
                                <NavDropdown.Item><Link to="/gallery-list/">Gallery List</Link></NavDropdown.Item>
                            </NavDropdown>

                            <NavDropdown title="Company" className="pr-5">
                                <NavDropdown.Item><Link to="/add-company">Add Company</Link></NavDropdown.Item>
                                <NavDropdown.Item><Link to="/company-list">Company List</Link></NavDropdown.Item>
                            </NavDropdown>
                                
                            <NavDropdown title="Brand" className="pr-5">
                                <NavDropdown.Item><Link to="/add-brand/">Add Brand</Link></NavDropdown.Item>
                                <NavDropdown.Item><Link to="/brand-list/">Brand List</Link></NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="Export" className="pr-5">
                                <NavDropdown.Item><Link to="/add-export/">Add Export</Link></NavDropdown.Item>
                                <NavDropdown.Item><Link to="/export-list/">Export List</Link></NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="Import" className="pr-5">
                                <NavDropdown.Item><Link to="/add-import/">Add Import</Link></NavDropdown.Item>
                                <NavDropdown.Item><Link to="/import-list/">Import List</Link></NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="Contacts" className="pr-5">
                                <NavDropdown.Item><Link to="/contact-list/">Contacts List</Link></NavDropdown.Item>
                            </NavDropdown>
                            </>
                            :
                            <>
                                <Link to="/login">Login</Link>
                            </>
                    }
                </Nav>
                {
                    localStorage.getItem('user-info') ?
                        <Nav className="pr-5">
                            <NavDropdown title={user && user.name} className="pr-5">
                                <NavDropdown.Item onClick={logout} >Logout</NavDropdown.Item>
                                <NavDropdown.Item><Link to={'/profile/'+user.id}>Profile</Link></NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                        : null
                }

            </Navbar>
        </div>
    );
};

export default Header;
