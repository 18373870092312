import React, {useState, useEffect} from 'react';
import mission from "../Media/mission.jpg";
import vision from "../Media/vision.jpg";
import Footer from "./Footer";
import "../App.css";
import Navbar from "./Navbar";
function About() {
  const [data, setData] = useState([])
    useEffect( () => {
        getData()
    },[])

    async function getData() {
        let result = await fetch('https://api.jeebd.com/api/about-list')
        result = await result.json()
        setData(result)
        console.log(result)
    }
  return (
    <>
    <Navbar />
      {/* Hero Section */}
      <div className="container-fluid heading">
        <div className="headingbox">
          <h1>About Us</h1>
        </div>
      </div>
      {/* Hero Section */}
      {
                        data.map((item) =>
          <div className="container mt-5 mb-5" key={item.id}>
            <div className="row">
              <div className="col-12 col-lg-12 col-sm-6 mx-auto">
                <div className="row about gy-2">
                  <div className="col-lg-6 about_desc">
                    <h1>{item.title}</h1>
                    <p>
                    {item.description}
                    </p>
                    {/* <button>View More</button> */}
                  </div>
                  <div className="col-lg-6 ">
                    <img className="about_img" src={'https://api.jeebd.com/about/'+item.file_path} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          )
        }
      {/* Mission */}
      <Footer />
    </>
  );
}

export default About;

// App.js


