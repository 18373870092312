import React, {useState, useEffect} from 'react';
import Footer from "./Footer";
import Navbar from "./Navbar";
import importimg from "../Media/import-top.jpeg";
function Import() {
  const [data, setData] = useState([])
    useEffect( () => {
        getData()
    },[])

    async function getData() {
        let result = await fetch('https://api.jeebd.com/api/import-list')
        result = await result.json()
        setData(result)
    }
  return (
    <>
    <Navbar />
      {/* Hero Section */}
      <div className="container-fluid heading">
        <div className="headingbox">
          <h1>Imports</h1>
        </div>
      </div>
      {/* Hero Section */}
      {/* export details */}
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-12 col-lg-12 col-sm-6 mx-auto">
            <div className="row about gy-1">
              <div className="col-lg-6 about_desc">
                <h1>Our Imports</h1>
                <p>
                From 1996 our company have  started importing  various Item from Various Country and manufacturar with best quality products. Some of our imported item list are:<br></br>

USB PC Cable, Progr. Hardaware, Inductive transceder, Encoder, Cap, Plug, Cable Converter, Techno Generator, Encoder, Coupling, Spring Disc Coupling , Pressure Trasducer, Ultrasonic Level Transducer,Radar Level transmitter, Level porbe. Pressure Transmitter ,Level Switch (E+H) , Electro-pneumatic Positioner,Spotlight, Circuit Board, Fan, Pressure Switch, Brush, Wiring harness, Fause, Timer, Condensate monitor ,Rectifier Interface Board  way valve, pressure Reducing valve, Seal Kit, Current Control valve, Pum, Cylinder, Pressure switch, Adaptor Plate, Hydraulic Unit etc<br></br>

from Siemens, TWK_ELEKTRONIK,Baumer Hübner , E+H ,ABB Automation, Bosch Rexroth and many more manufacturar.
                </p>
                {/* <button>View More</button> */}
              </div>
              <div className="col-lg-6 ">
                <img className="about_img" src={importimg} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="client container mt-5 mb-5">
        <div className="col-12 col-lg-12 col-sm-12 mx-auto">
          <div className="row">
            {
            data.map((item) =>
            <div className="col-lg-3 col-sm-6 col-6 col-md-4" key={item.id}>
              <div className="clients">
                <img className="client" src={'https://api.jeebd.com/import/'+item.file_path} alt="" />
              </div>
            </div>
            )
            }
          </div>
        </div>
      </div>
      {/* export details */}
      <Footer />
    </>
  );
}

export default Import;
